import React, { useEffect, useState } from 'react'

import './chemical.css'

const Chemical = () => {
  let [currentDate,setCurrentDate]=useState(new Date())
 
  
let today=new Date('2024-10-03')

console.log(today,'today');

const yyyy = today.getFullYear();
let mm = today.getMonth() + 1; // Months start at 0!
let dd = today.getDate()+1;

if (dd < 10) dd = '0' + dd;
if (mm < 10) mm = '0' + mm;

console.log(dd,'dd',mm,'mm',yyyy,'dd mm yyyy');

// const formattedToday = dd + '/' + mm + '/' + yyyy;



  const data=[
    {name:'Soda Ash (Light)',images:'/sodaashlight.jpeg',usage:['Detergents, Chemicals, Soaps','Textile, Paper, Food','Casting Industry'],download:'/images/pdf/sodaashlight.pdf'},
    {name:'Soda Ash (Dense)',images:'/sodaashdense.jpeg',usage:['Detergents','Chemicals','Glass'],download:'/images/pdf/sodaashdense.pdf'},
    {name:'Sodium Bicorbonate',images:'/sodiumbicorbonate.jpeg',usage:['Baking,Beverages','Tannery','Fire Extinguisher'],download:'/images/pdf/sodiumbicorbonate.pdf'},
    {name:'Salt',images:'/salt.jpg',usage:['Baking,Beverages','Tannery','Fire Extinguisher'],download:'/images/pdf/sodiumbicorbonate.pdf'}
  ]
  useEffect(()=>{
    window.scroll(0,0)
      },[])
  return (
    <div>
        <div className="about">
        <div className="container">
                      <div className="section-header m-0">
                   
                    <h2>Chemical</h2>
                      </div>
<div className="chemical" style={{background:'#F2F2F2',width:'95%',margin:'0px 40px 100px 10px'}}>
{data?.map((item)=>{return(
  <>
  <div className="chemical-inner-div">
   <div className="name">{item.name}</div> 

   <div className="image-div">

   <img src={item.images} alt={item.images}/>

   </div>
    <div className="usage">
      <h3 className='bg-dark text-light'>Applied In</h3>
      {item?.usage.map((item)=>{return(
        <>
        <ul className='text-center'>
          <li>{item}</li>
        </ul>
        
        </>
      )})}
    </div>
<div className="download">
<a href={item.download} target='_blank'><button className='btn btn-primary bg-primary'>Specification Pdf</button> </a>
</div>
  </div>
  </>
)})}

</div>
</div></div>
    </div>
  )
}

export default Chemical

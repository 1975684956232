import React from 'react'

const AboutUs = () => {
  return (
    <div>
          <div className="about mt-3">
            <div className="container">
            <h2 className='text-center'>Welcome to Our Site</h2>
                <div className="section-header">
                    
                    <h2>abdul Latif trading and development </h2>
                    <p className='text-left'>
                    M/S abdul Latif Trading and Development is a registered firm with Fedral Board of Revnue (FBR) under registration # 7359859-1 since 23rd of december 2016.ATD is registered with Haripur Chamber of Commerce & Industry. ATD started its business since january 2017. Objective of ATD is to provide quality Products And Services with efficient and effective resources utilization while mentaining the best business standard and ethics. Prior to starting business in Pakistan, we have 7 years experience in saudi arabia doing business in trading and construction. Saudi Arabia Electric Supply Company was one of the main client,                     </p>
                </div>
                
                <div className="row align-items-center">
                    <div className="col-md-12">
                        <div className="about-img">
                            <img src="/images/others/others (40).jpg" alt="" className="img-fluid"/>
                         
                        </div>
                        <div className="about-content">
                            
                            <p>
                            Based in Haripur we have already established good business terms while leading organization
 of Pakistan like HMC Taxila, Pakistan Ordinance Factory Wah, and other many local industries
  and traders. in such a short span of time it is all our hard working and experience that we have acheived a recognition in market.
  we hope to play a positive role in pakistan economy by bringing competence 
  and quality in market.
  ATD has commenced an import from republic of china and deals in nin ferrous
  i-e zinc ingots, alumunium ingots and copper ingots.                          </p>
  <h2 className='text-right'>(Muhammad Zakariyya)</h2>
  <h2 className='text-right'>Chief Executive</h2>
                           
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="about-img">
                            <img src="images/electronics/crashBarriers/cb (10).jpg" alt="" className="img-fluid" style={{height:'405px'}}/>
                        </div>
                        <div className="about-content">
                            <h2>Constracts in Saudi Arabia</h2>
                            <p>1. Saudi Service For Electro Mechanical Works co. (SSEM).</p>
                            <p>2. AlToukhi Company of industry, trading and contracting.</p>
                            <p>3. Al Babtain Contracting company.</p>
                            <p>4. Nour Communication company.</p>
                            {/* <a className="btn" href="#">Read More</a> */}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="about-img">
                            <img src="/images/others/others (19).jpg" alt="" className="img-fluid"/>
                        </div>
                        <div className="about-content">
                            <h2>
                            projects as Sub-Contractor</h2>
                            <p>  1. Hail-2cc simple cycle to combined cycle power plant.</p>
                            <p> 2. 380/132 kv ss bsp 9014 </p>
                            <p> 3. 380/132 kv ss bsp 9018</p>
                            <p>  4. 380/13.8 kv sub station 8130</p>
                            {/* <a className="btn" href="#">Read More</a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutUs

import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Foooter from './components/Foooter/Foooter';
import Home from './components/Home/Home';
import Mechanical from './components/pages/Mechanical';
import Navbar2 from './components/Navbar/Navbar2';
import Electrical from './components/pages/Electrical';
import ImageGallery from './components/pages/ImageGallery';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from 'react';
import { useState } from 'react';
import Chemical from './components/chemical/Chemical';
import Navbar3 from './components/Navbar/Navbar3';
import ElectricalImages from './components/pages/ElectricalImages';
import AboutUs from './components/AboutUs/AboutUs';
import OurServices from './components/Services/OurServices';
function App() {
  let [currentDate,setCurrentDate]=useState(new Date())
 
  
  let today=new Date('2024-10-25')
  
  console.log(today,'today');
  
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate()+1;
  
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
    
    useEffect(()=>{
  window.scroll(0,0)
    },[])
  return (
   <>
   {currentDate<=today?
    <Router>
    <Navbar/>

    <Routes>
    <Route path='/' element={<Home/>}/>
    <Route path='/images/:slug' element={<ImageGallery/>}/>
    <Route path='/images/electrical' element={<Navbar3/>}/>
    <Route path='/images/electrical/:slug' element={<ElectricalImages/>}/>
    <Route path='/chemical' element={<Chemical/>}/>
    <Route path='/mechanical' element={<Mechanical/>}/>
    <Route path='/electrical' element={<Electrical/>}/>
    <Route path='/aboutus' element={<AboutUs/>}/>
    <Route path='/services' element={<OurServices/>}/>
    </Routes>
    <Foooter/>
    </Router>
:'WebSite Under Maintenance'}
   </>
  );
}

export default App;

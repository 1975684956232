import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {
  return (
    <div>
             <div id="nav" className='main-nav'>
            <div className="container-fluid">
                <nav className="navbar navbar-expand-md bg-dark navbar-dark">
                    <Link to="/" className="navbar-brand">
                        <img src="/logo2.png" alt="Logo" style={{marginTop:'-45px'}}/>
                    </Link>
                    <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                        <div className="navbar-nav ml-auto">
                        <Link to="/" className="nav-item nav-link">Home</Link>
                        <Link to="/chemical" className="nav-item nav-link">Chemical</Link>
                            <Link to="/mechanical" className="nav-item nav-link">Mechanical</Link>
                            <Link to="electrical" className="nav-item nav-link">Electrical</Link>
                             <Link to="/images/:slug" className="nav-item nav-link">Images</Link>
                        
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
  )
}

export default Navbar
